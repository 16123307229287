export const KEEP_SIGNED_IN = "KEEP_SIGNED_IN";
export const USER_ACCESS_TOKEN = "USER_ACCESS_TOKEN";

export const CUSTOMER_SEARCHED_VEHICLE = "CUSTOMER_SEARCHED_VEHICLE";
export const CUSTOMER_INSPECTION_DATA = "CUSTOMER_INSPECTION_DATA";

export const INSPECTION_DEALERSHIP = "INSPECTION_DEALERSHIP";

export const Breakpoints = {
  mobile: 640,
  tablet: 768,
  desktop: 1024,
  desktopWide: 1280,
};

export enum FLOW_TYPE {
  RETAIL_DEALERSHIP = "RETAIL_DEALERSHIP",
  WHOLESALE_DEALERSHIP = "WHOLESALE_DEALERSHIP",
  MP_DEALERSHIP = "MP_DEALERSHIP",
}

export enum INSPECTION_STEP {
  LINK_SENT = "link-sent",
  VEHICLE_INFO = "vehicle-info",
  SPEC_OPTIONS = "spec-options",
  INSPECTION_SUBMITTED = "inspection-submitted",
}

export enum INSPECTION_TYPE {
  RETAIL = "retail",
  WHOLESALE = "wholesale",
}

export enum VEHICLE_ROUTES {
  Unvalued = "Unvalued",
  Valued = "Valued",
  Stocklist = "Stocklist",
}

export const DEFAULT_ITEMS_PER_PAGE = 15;
export const MAX_TENDER_BID = 1999999;

export const DEALERSHIP = "DEALERSHIP";
export const EXTERNAL = "EXTERNAL";

export const MOTOR_MARKET = "MotorMarket";
export const MOTOR_MARKET_MY_LISTINGS = "My Listings";
export const MOTOR_MARKET_SELLING = "Selling";
export const MOTOR_MARKET_BUYING = "Buying";

export const DISABLED_STATE_COLOR = "#9696A0";

export const ERROR_MESSAGE = "Something went wrong. Please try again.";
export const ERROR_NOTES_255_CHARACTERS_LIMIT =
  "The note must not be greater than 255 characters.";

export const ABLY_NOTIFICATIONS_EVENT =
  "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";

export enum StorageKeys {
  RetailDealershipValuationList = "RETAIL_DEALERSHIP_VALUATION_LIST",
  RetailDealershipValuationScroll = "RETAIL_DEALERSHIP_VALUATION_SCROLL",
  RetailDealershipNegotiationList = "RETAIL_DEALERSHIP_NEGOTIATION_LIST",
  RetailDealershipNegotiationScroll = "RETAIL_DEALERSHIP_NEGOTIATION_SCROLL",
  RetailDealershipAcquisitionList = "RETAIL_DEALERSHIP_ACQUISITION_LIST",
  RetailDealershipAcquisitionScroll = "RETAIL_DEALERSHIP_ACQUISITION_SCROLL",
  RetailDealershipCustomerList = "RETAIL_DEALERSHIP_CUSTOMER_LIST",
  RetailDealershipCustomerScroll = "RETAIL_DEALERSHIP_CUSTOMER_SCROLL",
  WholesaleDealershipAcquisitionList = "WHOLESALE_DEALERSHIP_ACQUISITION_LIST",
  WholesaleDealershipExternalAcquisitionList = "WHOLESALE_DEALERSHIP_EXTERNAL_ACQUISITION_LIST",
  WholesaleDealershipAcquisitionScroll = "WHOLESALE_DEALERSHIP_ACQUISITION_SCROLL",
  WholesaleDealershipValuationList = "WHOLESALE_DEALERSHIP_VALUATION_LIST",
  WholesaleDealershipExternalValuationList = "WHOLESALE_DEALERSHIP_EXTERNAL_VALUATION_LIST",
  WholesaleDealershipValuationScroll = "WHOLESALE_DEALERSHIP_VALUATION_SCROLL",
  WholesaleDealershipNegotiationList = "WHOLESALE_DEALERSHIP_NEGOTIATION_LIST",
  WholesaleDealershipExternalNegotiationList = "WHOLESALE_DEALERSHIP_EXTERNAL_NEGOTIATION_LIST",
  WholesaleDealershipNegotiationScroll = "WHOLESALE_DEALERSHIP_NEGOTIATION_SCROLL",
  WholesaleDealershipDealershipsList = "WHOLESALE_DEALERSHIP_DEALERSHIPS_LIST",
  WholesaleDealershipDealershipsListScroll = "WHOLESALE_DEALERSHIP_DEALERSHIPS_LIST_SCROLL",
  MotorIntelValuationCertificate = "MOTOR_INTEL_VALUATION_CERTIFICATE",
  ValuationCertificate = "VALUATION_CERTIFICATE",
  NotificationsUnreadState = "NOTIFICATIONS_UNREAD_STATE",
  LastTimeNotificationsChecked = "LAST_TIME_NOTIFICATIONS_CHECKED",
  WholesaleDealershipCustomerDocumentsList = "WHOLESALE_DEALERSHIP_CUSTOMER_DOCUMENTS_LIST",
  MotorMarketMyListings = "MOTOR_MARKET_MY_LISTINGS",
  MotorMarketBuying = "MOTOR_MARKET_BUYING",
  MotorMarketMyListingsFilterStatus = "MOTOR_MARKET_MY_LISTINGS_FILTER_STATUS",
  MotorMarketBuyingFilterStatus = "MOTOR_MARKET_BUYING_FILTER_STATUS",
  TenderSellingList = "TENDER_SELLING_LIST",
  TenderSellingScroll = "TENDER_SELLING_SCROLL",
  TenderBuyingList = "TENDER_BUYING_LIST",
  TenderBuyingScroll = "TENDER_BUYING_SCROLL",
  VehicleInformationFactoryOptions = "VEHICLE_INFORMATION_FACTORY_OPTIONS",
  PPSRList = "PPSR_LIST",
  Verification = "VERIFICATION",
  PPSRHistory = "PPSR_HISTORY",
  GLOBAL_SEARCH_SUMMARY = "GLOBAL_SEARCH_SUMMARY",
  ACQUISITION_HISTORY = "ACQUISITION_HISTORY",
  VALUATION_HISTORY = "VALUATION_HISTORY",
  VEHICLE_NOTES = "VEHICLE_NOTES",
  RETAIL_UNVALUED_LIST = "RETAIL_UNVALUED_LIST",
  RETAIL_VALUED_LIST = "RETAIL_VALUED_LIST",
  RETAIL_STOCKLIST_LIST = "RETAIL_STOCKLIST_LIST",
  WHOLESALE_UNVALUED_LIST = "WHOLESALE_UNVALUED_LIST",
  WHOLESALE_VALUED_LIST = "WHOLESALE_VALUED_LIST",
  WHOLESALE_STOCKLIST_LIST = "WHOLESALE_STOCKLIST_LIST",
  VEHICLE_INFO_CONTRACTID = "VEHICLE_INFO_CONTRACTID",
}

export enum FeatureRoute {
  RetailDealership = "/retail-dealership",
  RetailDealershipValued = "/retail-dealership/valued",
  RetailDealershipStocklist = "/retail-dealership/stocklist",
  WholesaleDealership = "/wholesale-dealership",
  WholesaleDealershipValued = "/wholesale-dealership/valued",
  WholesaleDealershipStocklist = "/wholesale-dealership/stocklist",
  Tender = "/tender",
  TenderSell = "/tender/selling",
  TenderBuy = "/tender/buying",
  MotorMarket = "/motor-market",
  MotorMarketSell = "/motor-market/my-listings",
  MotorMarketBuy = "/motor-market/buying",
}

export const MAX_CHARACTERS_NOTES = 255;

export const SUMMARY_PAGE_POLLING_INTERVAL = 30000;

export const ACCESS_DENIED_ROUTE = "/access-denied";

export const VEHICLE_SUMMARY_TITLE = "Vehicle Summary";

export const SHARPNESS_TRESHOLD = 80;

export enum COMPLIANCE_STATUS {
  REGISTERED = "REGISTERED",
  UNREGISTERED = "UNREGISTERED",
}

export enum DEVICE_TYPES {
  TABLET = "tablet-web",
  DESKTOP = "desktop-web",
}

export const ALL_DEALERSHIPS = "all";
